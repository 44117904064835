<template>
    <div>
        <header-slot>
            <template #actions>
                <b-button variant="success" @click="openModalNewLetter">
                    <feather-icon icon="PlusIcon" /> Create
                </b-button>
            </template>
        </header-slot>
        
        <b-nav
        card-header
        pills
        class="m-0"
        >
            <b-nav-item
              :to="{ name: 'pending-directs-round-specialistdigital' }"
              exact
              :active=" $route.meta.typeTab === 'pending'"
              :link-classes="['px-3', bgTabsNavs]"
              >
              PENDING
                <span class="ml-2" v-if="pendingDirectRounds > 0">
                    <span>
                        <feather-icon
                        icon
                        :badge="pendingDirectRounds"
                        badge-classes="badge-danger badge-glow"
                        />
                    </span>
                </span>
              </b-nav-item>
              
              <b-nav-item 
              :to="{ name: 'completed-directs-round-specialistdigital' }"
              exact 
              :active=" $route.meta.typeTab === 'completed'"
              :link-classes="['px-3', bgTabsNavs]"
              >
              COMPLETED
              </b-nav-item>
        </b-nav>
        
        <b-card no-body class="border-top-primary border-3 border-table-radius px-0">
            <router-view :key="keyTable" />
        </b-card>

        <modal-create-round-letter 
            v-if="modalNewLetters"
            :direct="true"
            @updateTable="closeModalLetter"
            @hideModal="modalNewLetters = false;"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ModalCreateRoundLetter from "@/views/specialist-digital/views/cr-realtor/components/modal/ModalCreateRoundLetter.vue";
export default {
    components: {
        ModalCreateRoundLetter,
    },
    data() {
        return {
            modalNewLetters: false,
            keyTable: 0
        }
    },
    computed: {
        ...mapGetters({
            pendingDirectRounds: 'SpecialistsDigitalDirectRoundsStore/G_COUNTER_PENDING_DIRECT_ROUNDS',
        })
    },
    methods: {
        openModalNewLetter() {
            this.modalNewLetters = true
        },
        closeModalLetter() {
            this.modalNewLetters = false
            this.keyTable++;
        },
    }
}
</script>